@import "../../styles/shared.scss";

.customIcon {
  position: relative;
  max-width: 492px;
  :focus {
    outline: none;
    border: 1px solid $color-form-blue;
  }
}
.inputLabel {
  display: block;
  margin-bottom: 7px;
  font-weight: 500;
}
.smallLabel {
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $color-primary-dark;
  margin-bottom: 5px;
}

.tableIcon::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 8px;
  top: 7px;
  right: 2px;
  background-image: url("../../public/svg/tableSelectIcon.svg");

  pointer-events: none;
}

.formIcon::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 14px;
  right: 12px;
  background-image: url("../../public/svg/formSelectArrow.svg");
  background-repeat: no-repeat;
  pointer-events: none;
}

.select {
  width: 100%;
  box-shadow: none;
}

.table {
  appearance: none;
  border: none;
  background-color: transparent;
}

.form {
  appearance: none;
  padding: 8px 10px;
  border: 1px solid $color-secondary-mediumGrey;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-primary-dark;
  margin-bottom: 12px;
}
