@import '../../styles/shared.scss';

.sliderWrap {
    width: 100%;
}

.arrow {
    @extend %button;
    position: absolute;
    top: 50%;
    width: 28px;
    height: 28px;

    transform: translateY(-50%);
    opacity: 0.8;
    z-index: 2;

    &.black path {
        fill: $color-primary-dark;
    }

    &.next {
        right: 24px;
    }

    &.prev {
        left: 24px;
        transform: translateY(-50%) rotate(-180deg);
    }

    @media screen and (max-width: 767px) {
        &.next {
            right: 12px;
        }

        &.prev {
            left: 12px;
        }
    }
}

.dotsWrap {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width: 767px) {
        margin-top: 18px;
    }

    &.modal {
        position: absolute;
        bottom: 13px;
        left: 50%;
        margin-top: 0;

        transform: translateX(-50%);

        @media screen and (max-width: 767px) {
            position: absolute;
            bottom: -18px;
            height: 5px;
        }
    }
}

.dotsWrap ul {
        @extend %list;
        display: flex;
        padding-left: 12px;

        @media screen and (max-width: 767px) {
            padding-left: 13px;
        }
    }

.dotsWrap.modal ul {
    padding-left: 13px;

    @media screen and (max-width: 767px) {
        padding-left: 7px;
    }
}

.dotsWrap ul li {
    display: inline-block;
    margin-right: 22px;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-right: 21px;
    }
}

.dotsWrap.modal ul li {
    margin-right: 21px;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
         margin-right: 15px;
    }
}

.dotsWrap :global(li.slick-active) {
    margin-right: 8px;
    margin-left: -12px;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-left: -13px;
    }
}

.dotsWrap.modal :global(li.slick-active) {
    margin-right: 8px;
    margin-left: -13px;

    &:last-child {
        margin-right: 0;
    }

    @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-left: -7px;
    }
}

.dotsWrap ul li button {
    @extend %button;
    display: block;
    height: 10px;
    width: 10px;

    color: transparent;

    border-radius: 5px;
    background: $color-primary-dark;
    opacity: 0.5;

    transition: all 500ms;

    @media screen and (max-width: 767px) {
        height: 7px;
        width: 7px;
    }

    &:hover,
    &:focus {
        background: $color-primary-dark;
        opacity: 1;
    }
}

.dotsWrap.modal ul li button {
    height: 7px;
    width: 7px;

    background: $color-primary-light;

    transition: all 500ms;

    @media screen and (max-width: 767px) {
        height: 5px;
        width: 5px;

        background: $color-primary-dark;
    }

    &:hover,
    &:focus {
        background: $color-primary-light;
        opacity: 1;

        @media screen and (max-width: 767px) {
            background: $color-primary-dark;
        }
    }
}

.dotsWrap :global(li.slick-active) button {
    width: 34px;
    border-radius: 5px;
    background-color: $color-primary-dark;
    opacity: 1;

    @media screen and (max-width: 767px) {
        width: 30px;
        border-radius: 8px;
    }
}

.dotsWrap.modal :global(li.slick-active) button {
    width: 30px;
    border-radius: 8px;
    background-color: $color-primary-light;
    opacity: 1;

    @media screen and (max-width: 767px) {
        width: 20px;
        background-color: $color-primary-dark;
    }
}
