@import "styles/colors";
.sliderWrapper {
  object-fit: cover;
}
.imageContainer {
  max-width: 100%;
  padding-top: 100%;
  background: $color-secondary-yellowish;
  position: relative;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.emptyImagesWrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding-top: 18px;
  margin-left: 9px;
}
.scrollBox {
  flex: 1 1 75%;
  height: 100%;
  overflow-x: auto;
}
.imagesWrapper {
  height: 100%;
  flex: 1 1 75%;
  display: flex;
}
.emptyImageContainer {
  flex: 0 0 116px;
  height: 120px;
  margin-right: 18px;
  padding: 0;
  background-color: rgba(242, 186, 76, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-accent-yellow;
  position: relative;
}
.addImage {
  cursor: pointer;
}
.image {
  width: 100%;
  object-fit: cover;
}
.deleteIcon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-primary-light;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
