@import "styles/colors";
.wrapper {
  display: flex;
  border-radius: 3px;
  position: relative;
  background: $color-primary-light;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px;
  }
  @media screen and (max-width: 424px) {
    flex-direction: column;
    padding: 18px 12px 0;
  }
}
.imageContainer {
  border-radius: 3px 3px 0 0;
  flex: 1 1 50%;
  max-width: 540px;
  @media screen and (max-width: 767px) {
    max-width: 360px;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}
.bgImage {
  max-width: 100%;
  max-height: 650px;
  vertical-align: top;
}

.icon {
  margin-left: 18px;
  height: 21px;
  width: 18px;
  cursor: pointer;
}

.contentWrapper {
  background: $color-primary-light;
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 24px 24px 0;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}
.heading {
  font-size: 36px;
  font-weight: bold;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: $color-primary-dark;
  margin: 0;
  padding-top: 12px;

  @media screen and (max-width: 767px) {
    font-size: 32px;
  }
  @media screen and (min-width: 768px) and (max-width: 950px) {
    font-size: 34px;
    line-height: 40px;
  }
}
.street {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.3px;
  padding-bottom: 12px;

  @media screen and (min-width: 768px) and (max-width: 950px) {
    margin: 10px 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
  }
}
.infoWrapper {
  padding-top: 10px;
  @media screen and (min-width: 768px) and (max-width: 950px) {
    padding-top: 10px;
  }
}
.contactBtn {
  margin: 20px 0 0;
  @media screen and (min-width: 768px) {
    margin: 20px 0;
  }
}
@media screen and (max-width: 767px) {
  .bgImage {
    width: 100%;
    height: 100%;
  }
  .contentWrapper {
    padding: 0;
  }
  .tagBtn {
    position: absolute;
    top: 12px;
    left: 6px;
  }
  .street {
    margin-bottom: 12px;
  }
  .infoWrapper {
    padding-top: 30px;
  }
  .contactBtn {
    margin: 30px 0 36px;
  }
}
.sliderBox .dots.modalDots {
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -18px;
    height: 5px;
  }
}
.sliderBox .dots.modalDots ul {
  @media screen and (max-width: 767px) {
    padding-left: 7px;
  }
}
.sliderBox .dots.modalDots ul li {
  @media screen and (max-width: 767px) {
    margin-right: 15px;
  }
}
.sliderBox .dots.modalDots :global(li.slick-active) {
  @media screen and (max-width: 767px) {
    margin-right: 8px;
    margin-left: -7px;
  }
}
.sliderBox .dots.modalDots ul li button {
  @media screen and (max-width: 767px) {
    height: 5px;
    width: 5px;
    background: $color-primary-dark;
  }
  &:hover,
  &:focus {
    @media screen and (max-width: 767px) {
      background: $color-primary-dark;
    }
  }
}
.sliderBox .dots.modalDots :global(li.slick-active) button {
  @media screen and (max-width: 767px) {
    width: 20px;
    background-color: $color-primary-dark;
  }
}

.buttonWrap {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;
}
