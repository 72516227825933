@import "../../styles/shared.scss";

.wrapper {
  display: inline-block;
  padding: 7.5px;
  border-radius: 3px;
}

.content {
  font-size: 9px;
  font-weight: 600;
  line-height: 1.22;
  color: $color-primary-light;
  user-select: none;
}
