@import "styles/colors";

.timeWrapper {
  max-width: 492px;
  margin-bottom: 12px;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: $color-primary-light;
  border: 1px solid $color-secondary-mediumGrey;
  border-radius: 3px;
  position: relative;
}

.timeWrapperFocus {
  border: 1px solid $color-form-blue;
}

.timeLabel {
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $color-primary-dark;
  margin-bottom: 5px;
}

.open,
.close {
  width: 110px;
  border: 1px solid $color-secondary-mediumGrey;
  border-radius: 3px;
  padding: 5px;
  background-color: white;
  font-size: 14px;
  color: $color-primary-dark;
}

.open {
  margin-right: 10px;
}

.close {
  margin-left: 10px;
}

.open:focus,
.close:focus {
  outline: none;
  border-color: $color-form-blue;
}
